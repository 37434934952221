<style lang="scss" src="../../styles/modal.scss"></style>

<template>
  <section class="modal swiper-no-swiping">
    <router-view />
  </section>
</template>

<script>
export default {
  name: "Modal"
};
</script>
